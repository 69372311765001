<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header activity_detail">
            <a class="icon iconButton third" @click.prevent="$router.push('/campaign')">
                <font-awesome-icon
                    icon="fa-solid fa-chevron-left"
                    size="lg"
                ></font-awesome-icon>
            </a>
            <span class="fL">專屬任務</span>
            <div class="icon iconButton"></div>
        </section>
        <!-- Main -->
        <section class="main activity_detail">
            <div class="img cover">
                <img src="@/assets/img/ticket-cover.png" />
            </div>
            <div class="flexV">
                <span class="fXXL third">{{ campaign.campaignName }}</span>
                <span class="fM gray"
                    >{{ tsToDatetime(campaign.campaignStartTime) }} -
                    {{ tsToDatetime(campaign.campaignEndTime) }}</span
                >
            </div>
            <span class="fM">{{ campaign.campaignDesc }}</span>
            <!-- 進度條 -->
            <div
                class="progress flexH width padding margin"
                v-show="processBarDisplay(campaign)"
            >
                <span class="fL bold primary">{{ calNumerator(campaign) }}/{{
                            calMissions(campaign)
                        }}</span>
                <div class="bar flexH width rounded">
                    <div :style="{ width: calProgress(campaign) + '%' }"></div>
                </div>
                <!-- <div class="reward flexV center">
                    <div class="img"><img src="@/assets/icon/point.svg" /></div>
                    <span class="fM nowrap">100點</span>
                </div> -->
            </div>
            <a class="button rounded" v-if="callToActionObj.entryUrl" :href="callToActionObj.entryUrl">
                <span class="fM">前往任務</span>
            </a>
        </section>
    </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "vuex";

export default {
    name: "QuestionDetail",
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
                console.log('campaignId:', to.params.campaignId)
                // if(Object.keys(that.campaign) == 0) next("/error")
            } else {
                next("/error");
            }
        });
    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            callToActionObj: {}
        };
    },
    computed: {
        ...mapState(["user", "userInfo", "campaign"])
    },
    mounted() {
        console.log(this.campaign);
        this.calCampaign(this.$route.params.campaignId)
        .then((res) => {
            console.log('getCampaignInfo:', res.data);
            this.$store.commit("updateCampaign", res.data)
            this.$nextTick(function(){
                this.getCallToAction();
            })
            
        })
        .catch((e)=>{
            console.log('failed to get campaign info', e)
            this.$router.push('/error');
        })
        // this.getCallToAction()
    },
    methods: {
        tsToDate(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD");
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        calCampaign(campaignId) {
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/campaigns/${campaignId}`,
                methods: "GET"
            };
            return this.$http(config);
        },
        calMissions(campaign) {
            if(campaign && campaign.campaignPrototype == "stamp-collection" && campaign.missions && campaign.missions.length == 1) {
                let settings = campaign.missions[0].settings;
                if(settings && settings.milestones && settings.milestones.length > 0) {
                    let max = Math.max(...settings.milestones.map(milestone => milestone.completionCount));
                    return max;
                } else {
                    return 0;
                }
            } else {
                if (
                    campaign &&
                    campaign.missions &&
                    campaign.missions.length >= 0
                ) {
                    return campaign.missions.length;
                }
            }
            return 0;
        },
        calCompletedMissions(campaign) {
            if(campaign && campaign.campaignPrototype == "stamp-collection" && campaign.missions && campaign.missions.length == 1) {
                let participation = campaign.missions[0].participation;
                if(participation && participation.length > 0) {
                    return participation.filter(p => p.status == "completed").length;
                } else {
                    return 0;
                }
            } else {
                if (
                    campaign &&
                    campaign.missions &&
                    campaign.missions.length > 0 &&
                    campaign.missions.some((m) => m.participation)
                ) {
                    return campaign.missions.filter((m) => {
                        if (
                            m.participation &&
                            m.participation.length > 0 &&
                            m.participation[0].status == "completed"
                        ) {
                            return true;
                        }
                        return false;
                    }).length;
                }
            }
            return 0;
        },
        calNumerator(campaign) {
            let numerator = this.calCompletedMissions(campaign);
            let denominator = this.calMissions(campaign);
            return numerator > denominator ? denominator : numerator;
        },
        calProgress(campaign) {
            let numerator = this.calNumerator(campaign);
            let denominator = this.calMissions(campaign);
            return (numerator / denominator) * 100;
        },
        processBarDisplay(camppaign) {
            return camppaign.campaignPrototype == "stamp-collection";
        },
        callCallToActionAPI() {
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/campaigns/${this.campaign.campaignId}/run?uuid=${this.user.userId}`,
                methods: "GET",
            };
            return this.$http(config);
        },
        dummyCallToAction() {
            let result = {
                entryUrl: "https://www.google.com"
            }
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve({data: result});
                }, 300);
            })
        },
        getCallToAction() {
            console.log('getCallToAction', this.campaign);
            this.callCallToActionAPI()
            // this.dummyCallToAction()
            .then(res => {
                this.callToActionObj = res.data;
            })
            .catch(err => console.log(err));
        }
    }
};
</script>
